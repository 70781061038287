import { memo, useState } from 'react'
import {
    Button,
    Box,
    Flex,
    Image,
    ColorModeScript,
    useColorMode,
    Link,
    Text,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Portal,
} from '@chakra-ui/react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { ChevronLeftIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import ErrorFallback from './ErrorFallback'
import ColorModeSwitcher from './ColorModeSwitcher'
import Logo from '../assets/logoGb.svg'

function PageTemplate() {
    const { colorMode } = useColorMode()
    const [cookies, setCookie, removeCookie] = useCookies(['jwt_data'])
    const [currentSlide, setCurrentSlide] = useState(0)
    const [isLogged, setIsLogged] = useState(!!cookies.jwt_data)

    return (
        <Flex
            w={['90vw', '90vw', '90vw', '90vw', '80vw']}
            h={['94vh', '94vh', '94vh', '94vh', '80vh']}
            mt={['3vh', '3vh', '3vh', '3vh', '10vh']}
            ml={['5vw', '5vw', '5vw', '5vw', '10vw']}
            bg={colorMode === 'light' ? 'whiteAlpha.50' : 'blackAlpha.50'}
            borderRadius="xl"
            boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
            backdropFilter="blur(10px)"
            borderWidth="1px"
            borderColor={colorMode === 'light' ? 'whiteAlpha.500' : 'blackAlpha.500'}
            p={['2', '2', '2', '2', '5']}
        >
            <ColorModeScript />
            <Flex justifyContent="space-between" flexDirection="column" w="100%">
                <Flex
                    justifyContent="space-between"
                    alignItems="flex-start"
                    flexDirection="row"
                    pt="2"
                >
                    {currentSlide !== 0 || isLogged ? (
                        <Link href="/">
                            <Image width="75px" src={Logo} alt="Gamesberry" ml={3} />
                        </Link>
                    ) : (
                        <Box />
                    )}
                    <Box />
                    <ColorModeSwitcher />
                </Flex>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Outlet context={[currentSlide, setCurrentSlide, isLogged, setIsLogged]} />
                </ErrorBoundary>
                <Flex justifyContent="space-between" alignItems="flex-start">
                    <Box>
                        {currentSlide !== 0 && !isLogged ? (
                            <Button
                                variant="solid"
                                size="md"
                                colorScheme="pygmaBlue"
                                leftIcon={<ChevronLeftIcon />}
                                onClick={() => setCurrentSlide(0)}
                            >
                                Back
                            </Button>
                        ) : (
                            <Box />
                        )}
                    </Box>
                    <Box />
                    <Box>
                        <Popover>
                            <PopoverTrigger>
                                <Button
                                    variant="outline"
                                    size="md"
                                    colorScheme="pygmaOrange"
                                    borderRadius="100%"
                                    onClick={() => setCurrentSlide(0)}
                                >
                                    ?
                                </Button>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverHeader>Need help?</PopoverHeader>
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                        <Flex direction="column">
                                            <Button
                                                variant="link"
                                                colorScheme={
                                                    colorMode === 'light'
                                                        ? 'pygmaOrange'
                                                        : 'pygmaBlue'
                                                }
                                                onClick={() => setCurrentSlide(6)}
                                            >
                                                Help Page 1
                                            </Button>
                                            <Button
                                                variant="link"
                                                colorScheme={
                                                    colorMode === 'light'
                                                        ? 'pygmaOrange'
                                                        : 'pygmaBlue'
                                                }
                                                onClick={() => setCurrentSlide(7)}
                                            >
                                                Help Page 2
                                            </Button>
                                        </Flex>
                                    </PopoverBody>
                                    <PopoverFooter>
                                        <Text fontSize="xs" align="right">
                                            Copyright ©
                                            <Link href="https://lysden.art" isExternal>
                                                Lysden.art <ExternalLinkIcon mx="2px" />
                                            </Link>
                                            {` 2021-${new Date().getFullYear()}`}
                                        </Text>
                                    </PopoverFooter>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default memo(PageTemplate)
