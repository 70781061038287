import React, { StrictMode, lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react'
import { CookiesProvider } from 'react-cookie'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink } from '@apollo/client'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

import WaveBackground from './components/WaveBackground'
import LoadingScreen from './components/LoadingScreen'
import PageTemplate from './components/PageTemplate'

const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_URL}`,
    credentials: 'include',
})

const customLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        const context = operation.getContext()
        const {
            response: { headers },
        } = context
        if (headers.get('jwt_data') && headers.get('jwt_data').length > 0) {
            document.cookie = `jwt_data=${headers.get(
                'jwt_data'
            )}; path=/; max-age=604800; domain=${
                process.env.REACT_APP_DN
            }; secure=false; sameSite=lax;`
        }
        return response
    })
})

const client = new ApolloClient({
    link: customLink.concat(httpLink),
    cache: new InMemoryCache(),
})

const App = lazy(() => import('./pages/App'))
const Confirmation = lazy(() => import('./pages/Confirmation'))

const theme = extendTheme({
    colors: {
        pygmaOrange: {
            50: '#fff0e4',
            100: '#ffd5b1',
            200: '#ffc797',
            300: '#ffb97e',
            400: '#ffab64',
            500: '#ff9031',
            600: '#fd7500',
            700: '#e46900',
            800: '#ca5d00',
            900: '#974600',
        },
        pygmaBlue: {
            50: '#b3deff',
            100: '#80c8ff',
            200: '#66bcff',
            300: '#4db1ff',
            400: '#33a6ff',
            500: '#0090ff',
            600: '#0073cc',
            700: '#0065b3',
            800: '#005699',
            900: '#003a66',
        },
        pygmaMarroon: {
            50: '#ffb906',
            100: '#d29700',
            200: '#b88400',
            300: '#9f7200',
            400: '#856000',
            500: '#523b00',
            600: '#1f1600',
            700: '#050400',
            800: '#000000',
            900: '#000000',
        },
    },
})

ReactDOM.render(
    <StrictMode>
        <CookiesProvider>
            <ChakraProvider theme={theme}>
                <ApolloProvider client={client}>
                    <ColorModeScript />
                    <WaveBackground />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<PageTemplate />}>
                                <Route
                                    index
                                    element={
                                        <Suspense fallback={<LoadingScreen />}>
                                            <App />
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="confirm/:type/:id/:code"
                                    element={
                                        <Suspense fallback={<LoadingScreen />}>
                                            <Confirmation />
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="*"
                                    element={
                                        <main style={{ padding: '1rem' }}>
                                            <p>There&apos;s nothing here!</p>
                                        </main>
                                    }
                                />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ApolloProvider>
            </ChakraProvider>
        </CookiesProvider>
    </StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
