import React, { useEffect, useState, memo } from 'react'
import { Box, BoxProps, ContainerProps, useColorMode, useTheme } from '@chakra-ui/react'
import { animationControls, motion } from 'framer-motion'
import Wave from 'react-wavify'
import Particles from 'react-tsparticles'

import { useWindowSize } from '@react-hook/window-size/throttled'

export const MotionBox = motion<BoxProps>(Box)

function WaveBackground() {
    const { colorMode } = useColorMode()
    const theme = useTheme()
    const { pygmaBlue } = theme.colors
    const { pygmaMarroon } = theme.colors
    const { pygmaOrange } = theme.colors
    const [width, height] = useWindowSize()
    const [ratio, setRatio] = useState(1)

    useEffect(() => {
        setRatio(Math.ceil((width / height / (4 / 3)) * 100) / 100)
    }, [width, height])

    return (
        <Box pos="fixed" top="0">
            <Box
                bgGradient={`linear(${-15 * ratio}deg , ${
                    colorMode === 'light'
                        ? `${pygmaBlue[900]}, #fff9, #fff0, #fff9, ${pygmaBlue[200]}`
                        : `${pygmaOrange[200]}, #0009, #0000, #0009, ${pygmaOrange[900]}`
                })`}
                w="100vw"
                h="100vh"
                zIndex={-10}
                position="fixed"
            />

            <MotionBox
                position="fixed"
                w="200vw"
                h="200vh"
                transform="rotate(-15deg) translate(-50vw, 50vh)"
                zIndex={-9}
            >
                <Wave
                    style={{ height: 'inherit' }}
                    fill={colorMode === 'light' ? pygmaBlue[500] : pygmaOrange[500]}
                    opacity="25%"
                    paused={false}
                    filter="url(#myblurfilter)"
                    options={{
                        height: 100,
                        amplitude: 300,
                        speed: 0.075,
                        points: 3,
                    }}
                >
                    <defs>
                        <filter id="myblurfilter" width="120%" height="120%">
                            <feGaussianBlur stdDeviation="20" />
                        </filter>
                    </defs>
                </Wave>
            </MotionBox>
            <MotionBox
                position="fixed"
                w="200vw"
                h="200vh"
                /* transform='rotate(-1deg) translate(-50vw, -50vh)' */
                zIndex={-9}
            >
                <Wave
                    style={{ height: 'inherit' }}
                    fill="none"
                    stroke={colorMode === 'dark' ? pygmaBlue[500] : pygmaOrange[500]}
                    strokeWidth="1px"
                    filter="url(#f1)"
                    paused={false}
                    options={{
                        height: height * 0.4,
                        amplitude: 50,
                        speed: 0.17,
                        points: width / 125,
                    }}
                >
                    <defs>
                        <filter id="f1" x="0" y="0">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
                        </filter>
                    </defs>
                </Wave>
            </MotionBox>
            <MotionBox
                position="fixed"
                w="200vw"
                h="200vh"
                transform="rotate(-20deg) translate(-50vw, -50vh)"
                zIndex={-9}
            >
                <Wave
                    style={{ height: 'inherit' }}
                    fill={colorMode === 'light' ? pygmaBlue[500] : pygmaOrange[500]}
                    fillOpacity="35%"
                    strokeOpacity="75%"
                    opacity="50%"
                    paused={false}
                    stroke={colorMode === 'light' ? 'white' : 'black'}
                    strokeWidth="10px"
                    filter="url(#myblurfilter2)"
                    options={{
                        height: 800,
                        amplitude: 200,
                        speed: 0.15,
                        points: 2,
                    }}
                >
                    <defs>
                        <filter id="myblurfilter2" width="120%" height="120%">
                            <feGaussianBlur stdDeviation="25" />
                        </filter>
                    </defs>
                </Wave>
            </MotionBox>
            <MotionBox
                position="fixed"
                w="200vw"
                h="200vh"
                transform="rotate(-2deg) translate(-50vw, 0vh)"
                zIndex={-9}
            >
                <Wave
                    style={{ height: 'inherit' }}
                    fill="none"
                    stroke={colorMode === 'dark' ? pygmaBlue[300] : pygmaOrange[700]}
                    strokeWidth="1px"
                    opacity="25%"
                    paused={false}
                    options={{
                        height: height * 0.4,
                        amplitude: 50,
                        speed: 0.15,
                        points: width / 150,
                    }}
                />
            </MotionBox>
            <MotionBox
                position="fixed"
                w="200vw"
                h="200vh"
                transform="rotate(-10deg) translate(-50vw, -50vh)"
                zIndex={-9}
            >
                <Wave
                    style={{ height: 'inherit' }}
                    fill={colorMode === 'light' ? pygmaBlue[500] : pygmaOrange[500]}
                    fillOpacity="75%"
                    strokeOpacity="50%"
                    opacity="25%"
                    paused={false}
                    stroke={colorMode === 'light' ? 'white' : 'black'}
                    strokeWidth="10px"
                    filter="url(#myblurfilter3)"
                    options={{
                        height: 900,
                        amplitude: 500,
                        speed: 0.05,
                        points: 7,
                    }}
                >
                    <defs>
                        <filter id="myblurfilter3" width="120%" height="120%">
                            <feGaussianBlur stdDeviation="5" />
                        </filter>
                    </defs>
                </Wave>
            </MotionBox>
            <MotionBox
                position="fixed"
                w="200vw"
                h="200vh"
                transform="rotate(2deg) translate(-50vw, 0vh)"
                zIndex={-9}
            >
                <Wave
                    style={{ height: 'inherit' }}
                    fill="none"
                    stroke={colorMode === 'dark' ? pygmaBlue[50] : pygmaOrange[900]}
                    strokeWidth="2px"
                    opacity="15%"
                    paused={false}
                    options={{
                        height: height * 0.4,
                        amplitude: 50,
                        speed: 0.1,
                        points: width / 300,
                    }}
                />
            </MotionBox>
            <MotionBox position="fixed" w="100vw" h="100vh" zIndex={-10}>
                <Particles
                    id="bokeh"
                    options={{
                        particles: {
                            move: {
                                bounce: false,
                                direction: 'none',
                                enable: true,
                                outModes: 'out',
                                random: false,
                                speed: Math.random() / 10,
                                straight: false,
                            },
                            shadow: {
                                blur: 3,
                                color: colorMode === 'light' ? pygmaBlue[600] : pygmaOrange[300],
                                enable: true,
                            },
                            number: { density: { enable: true, area: 600 }, value: 8 },
                            opacity: {
                                value: 0.75,
                            },
                            color: {
                                value: colorMode === 'light' ? pygmaBlue[50] : pygmaOrange[500],
                            },

                            shape: {
                                type: 'polygon',
                                options: {
                                    polygon: {
                                        sides: 12,
                                        close: true,
                                    },
                                },
                            },
                            stroke: {
                                color: colorMode === 'light' ? pygmaBlue[100] : pygmaOrange[900],
                                width: 0.5,
                                opacity: 0.25,
                            },
                            size: {
                                value: 35,
                            },
                            zIndex: {
                                value: { min: 0, max: 5 },
                                opacityRate: 0.75,
                                sizeRate: 50,
                                random: false,
                            },
                            rotate: {
                                direction: 'random',
                                animation: {
                                    enable: true,
                                    speed: 3,
                                },
                            },
                            twinkle: {
                                particles: {
                                    enable: true,
                                    frequency: 0.9993,
                                    opacity: 0.05,
                                },
                            },
                        },
                    }}
                />
                <Particles
                    id="star"
                    options={{
                        particles: {
                            move: {
                                bounce: false,
                                direction: 'none',
                                enable: true,
                                outModes: 'out',
                                random: false,
                                speed: Math.random() / 100,
                                straight: false,
                            },
                            shadow: {
                                blur: 1,
                                color: '#ffffff',
                                enable: true,
                            },
                            number: { density: { enable: true, area: 600 }, value: 80 },
                            opacity: {
                                value: 0.75,
                            },
                            color: {
                                value: '#ffffff',
                            },
                            rotate: {
                                value: 45,
                            },
                            shape: {
                                type: 'star',
                                options: {
                                    star: {
                                        sides: 4, // the number of sides of the star
                                        inset: 3, // the inset length, 2 is the default and an acceptable value
                                        close: true,
                                    },
                                },
                            },
                            size: {
                                value: 1,
                            },
                            twinkle: {
                                particles: {
                                    enable: true,
                                    frequency: 0.9993,
                                    opacity: 0.05,
                                },
                            },
                        },
                    }}
                />
            </MotionBox>

            {/*                 <MotionBox
                    position={'fixed'} bottom='-25vh' left='-5vw' w='110vw' h='50vh'
                    >
                    <Wave style={{height: '100vh'}} fill='none' stroke='url(#linear)' strokeWidth='5px' opacity={'50%'}
                        paused={false}
                        options={{
                            height: 80,
                            amplitude: 50,
                            speed: 0.2,
                            points: 5
                        }}
                    >
                        <defs>
                            <linearGradient id="linear" x1="0%" y1="0%" x2="-5%" y2="100%" spreadMethod="reflect">
                                <stop offset="0%" stopColor="#0090ff"/>
                                <stop offset="7%"   stopColor="#fff0"/>
                            </linearGradient>
                        </defs>
                    </Wave>
                </MotionBox> */}
        </Box>
    )
}

export default memo(WaveBackground)
