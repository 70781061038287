import { Button, Text, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react'
import { ErrorBoundary } from 'react-error-boundary'

function ErrorFallback({
    error,
    resetErrorBoundary,
}: {
    error: Error
    resetErrorBoundary: (...args: Array<unknown>) => void
}) {
    return (
        <Alert status="error" display="flex" flexDirection="column" alignItems="flex-start">
            <AlertTitle mr={1} fontWeight="bold" display="flex" mb={5}>
                <AlertIcon />
                <Text>Something went wrong:</Text>
            </AlertTitle>
            <AlertDescription>
                <Text fontWeight="bold" mb={3}>
                    Here are some details you could forward to the Gamesberry team.
                </Text>
                <pre>{error.message}</pre>
                <pre>{error.stack}</pre>
            </AlertDescription>
            <Button
                type="button"
                variant="solid"
                colorScheme="red"
                onClick={resetErrorBoundary}
                mt={5}
            >
                Try again
            </Button>
        </Alert>
    )
}

export default ErrorFallback
