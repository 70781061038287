import React from 'react'
import { Flex, Image, Container, Spinner } from '@chakra-ui/react'

import logo from '../assets/logoGb.svg'

function LoadingScreen() {
    return (
        <Flex height="100vh" justifyContent="center" alignItems="center">
            <Container
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width={500}
            >
                <Image width="500px" src={logo} alt="Gamesberry" />
                <Spinner
                    size="md"
                    thickness="5px"
                    speed="5s"
                    emptyColor="blackAlpha"
                    color="orange"
                    label="Chargement en cours..."
                    height="100px"
                    width="100px"
                />
            </Container>
        </Flex>
    )
}

export default LoadingScreen
